// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.


//import Rails from 'rails-ujs'
import Rails from '@rails/ujs'

import 'packs/swal-confirm'

// Setup the event listeners of rails-ujs
Rails.start()

//require("@rails/ujs").start()
//require("turbolinks").start()
require("channels")
require('jquery');
require('jquery-ui-bundle');
require("slimScroll/jquery.slimscroll.min")
require("bootstrap/dist/js/bootstrap.min")
require("metismenu")
require("iCheck/icheck.min")
require("sparkline/index")
require('datatables.net-bs4')
//require('datatables.net-responsive-bs4');
require("select2/dist/js/select2.full")
require("dropzone")
require("packs/homer")
//require("@fancyapps/fancybox");
require("fancybox/jquery.fancybox");
require("kjua");
require("jquery.flot/jquery.flot");
require("jquery.flot/jquery.flot.resize");
require("jquery.flot/jquery.flot.time");
require("jquery.flot/jquery.flot.pie");
require("jquery.flot/jquery.flot.categories");
require("jquery.flot.tooltip");
require("bootstrap-datepicker");
require("vectormap/jquery-jvectormap-2.0.5.min")
require("vectormap/jquery-jvectormap-world-mill-en")
require("raphael/raphael")
require("morris.js/morris.min")
require("summernote/dist/summernote.min")
require("packs/custom.fle_upload")

window.Dropzone = require('dropzone/dist/dropzone')
Dropzone.autoDiscover = false;
$('[data-fancybox]').fancybox({
	touch: false
});

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

